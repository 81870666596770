import { Timestamp } from 'google-protobuf/google/protobuf/timestamp_pb'
import {
  GetMessagesRequest,
  Range,
  Request,
  GetChannelsRequest,
  SendMessageRequest,
  MarkAsReadRequest,
  GetChannelRequest,
  LeaveChannelRequest,
  GetStickersRequest,
  GetBizAccountsRequest,
  UpdateBlockStatusRequest,
  UpdateUserChannelRequest,
  GetUnreadCountRequest,
  GetChannelListHeaderRequest,
  CloseChannelListHeaderRequest,
  DeleteMessageRequest,
  ListAutoRepliesRequest,
  CreateAutoReplyRequest,
  DeleteAutoReplyRequest,
  UpdateAutoReplyRequest,
  GetAutoReplyMetadataRequest,
  UpdateAutoReplyMetadataRequest,
  SendPersistentMenuPostbackRequest,
} from 'external/rocket-chat/api/api_pb'
import { Message, User, Image, AutoReplyQuestion, AutoReplyAnswer, FilterID } from 'external/rocket-chat/api/model_pb'

import { store } from 'store'
import { sendMessageProps, userBlockProps } from 'types/ChatTypes'
import { imgTypeCheck } from 'types/typeChecker'
import { logLevel } from 'utils/consoleLevel'
import { gtagEmit } from 'utils/eventUtils'
import { autoReplyImage, autoReplyMetaProps } from 'types/PersistentTypes'
import { getUrlParams } from 'utils/snippet'

export const getMessagesQuery = ({
  channelId,
  messageId,
  limit = 20,
}: {
  channelId: string
  messageId: number
  limit?: number
  range?: Range
}) => {
  const message = new GetMessagesRequest()
  message.setChannelId(channelId)
  message.setMessageId(messageId)
  message.setLimit(limit)
  message.setRange(Range.LESS_EQUAL)

  const req = RequestConstructor()
  req.setName('GetMessagesRequest')
  req.setGetMessages(message)

  return req
}

export const getChannelItem = (channelId: string, bizAccountId?: number) => {
  const message = new GetChannelRequest()
  message.setChannelId(channelId)
  message.setWithLastMessage(true)
  const req = RequestConstructor()
  if (bizAccountId) {
    req.setBizAccountId(bizAccountId)
  }
  req.setName('GetChannelRequest')
  req.setGetChannel(message)
  return req
}

function RequestConstructor() {
  const req = new Request()
  const { bizAccountId, accountType } = getUrlParams()
  const authData = store.getState().authStore
  const selectUser = authData.selectUser
  if (accountType === 'business') {
    req.setBizAccountId(bizAccountId)
  } else {
    req.setId(`${selectUser}`)
  }
  return req
}

export const CHANNELS_LIMIT = 100

export const getChannelsQuery = ({
  limit = CHANNELS_LIMIT,
  pageKey,
  unreadCheck = false,
}: {
  limit?: number
  pageKey?: GetChannelsRequest.PagingKey.AsObject
  unreadCheck?: boolean
}) => {
  const message = new GetChannelsRequest()
  if (pageKey) {
    const newPageKey = new GetChannelsRequest.PagingKey()
    newPageKey.setKind(pageKey.kind)
    if (pageKey.time?.seconds && pageKey.time?.nanos) {
      const pageKeyTime = new Timestamp()
      pageKeyTime.setSeconds(pageKey.time.seconds)
      pageKeyTime.setNanos(pageKey.time.nanos)
      newPageKey.setTime(pageKeyTime)
    }
    message.setPagingKey(newPageKey)
  } else {
    const newPageKey = new GetChannelsRequest.PagingKey()
    newPageKey.setKind(2)
    message.setPagingKey(newPageKey)
  }
  message.setWithLastMessage(true)
  message.setLimit(limit)
  if (unreadCheck) {
    // NOTE(Andrew): unread check를 한 경우, FilterID에 FILTER_ID_UNREAD_COUNT를 넣어준다.
    message.setFilterId(FilterID.FILTER_ID_UNREAD_COUNT)
  }

  const req = RequestConstructor()
  req.setName('GetChannelsRequest')
  req.setGetChannels(message)
  return req
}

export const sendMessageWrapper = ({
  channelId,
  messageText,
  sender,
  receiver,
  imageList,
  type,
  channelType,
}: sendMessageProps) => {
  if (channelId && sender && (receiver || channelType === 2)) {
    if (imgTypeCheck(type)) {
      if (!imageList) {
        throw new Error(`imageList: ${imageList}`)
      }
    }
    const messageReq = new Message()
    const newSender = userGenerator(sender)
    if (receiver) {
      const newReceiver = userGenerator(receiver)
      messageReq.setReceiver(newReceiver)
    }
    messageReq.setChannelId(channelId)
    messageReq.setSender(newSender)
    messageReq.setType(type)
    if (type === 'TEXT') {
      messageReq.setContent(messageText || '')
    } else if (imgTypeCheck(type) && imageList) {
      messageReq.setImagesList(imageList)
    } else {
      logLevel.log(`type: ${type}`)
    }
    const sendMessageReq = new SendMessageRequest()
    sendMessageReq.setMessage(messageReq)

    const req = RequestConstructor()
    req.setName('SendMessageRequest')
    req.setSendMessage(sendMessageReq)

    const currentStore = store.getState()
    const authData = currentStore.authStore
    const selectType = authData.selectType
    const resourceType = currentStore.chatStore.selectChannelobj?.resourceType
    if (selectType === 'business') {
      gtagEmit('event', 'biz_send_message', {
        uid: sender.id,
        messageType: type,
        articleType: resourceType,
        cid: channelId,
      })
    } else {
      if (channelType === 2) {
        gtagEmit('event', 'group_send_message', {
          uid: sender.id,
          messageType: type,
          articleType: resourceType,
          cid: channelId,
        })
      } else {
        gtagEmit('event', 'normal_send_message', {
          uid: sender.id,
          messageType: type,
          articleType: resourceType,
          cid: channelId,
        })
      }
    }

    return req
  } else {
    throw new Error(`sender: ${sender}, receiver: ${receiver}, channelType: ${channelType}`)
  }
}

export const MessageReadCheck = ({ channelId }: { channelId: string }) => {
  const message = new MarkAsReadRequest()
  message.setChannelId(channelId)

  const req = RequestConstructor()
  req.setName('MarkAsReadRequest')
  req.setMarkAsRead(message)
  return req
}

export const LeaveChannelQuery = ({ channelId }: { channelId: string }) => {
  const message = new LeaveChannelRequest()
  message.setChannelId(channelId)

  const req = RequestConstructor()
  req.setName('LeaveChannelRequest')
  req.setLeaveChannel(message)
  return req
}

export const getStickerQuery = () => {
  const message = new GetStickersRequest()
  const req = RequestConstructor()
  req.setName('GetStickersRequest')
  req.setGetStickers(message)
  return req
}

export const getBizAccountQuery = () => {
  const message = new GetBizAccountsRequest()
  const req = new Request()
  req.setName('GetBizAccountsRequest')
  req.setGetBizAccounts(message)
  return req
}

const userGenerator = (user: User.AsObject) => {
  const newUser = new User()
  newUser.setId(user.id)
  newUser.setType(user.type)
  return newUser
}

export const userBlock = ({ status, user, targetUser }: userBlockProps) => {
  const message = new UpdateBlockStatusRequest()
  const req = RequestConstructor()
  if (user && targetUser) {
    message.setBlockStatus(status)
    const newSender = userGenerator(user)
    message.setUser(newSender)
    const newReceiver = userGenerator(targetUser)
    message.setTargetUser(newReceiver)
    req.setName('UpdateBlockStatusRequest')
    req.setUpdateBlockStatus(message)
    return req
  } else {
    throw new Error('user or targetUser is invalid')
  }
}

interface channelUpdateProps {
  channelId: string
  isMute?: boolean
  isFavorite?: boolean
}
export const channelUpdate = ({ channelId, isMute, isFavorite }: channelUpdateProps) => {
  const message = new UpdateUserChannelRequest()
  const req = RequestConstructor()
  message.setChannelId(channelId)
  if (typeof isMute === 'boolean') {
    message.setIsMute(isMute)
  }
  if (isFavorite) {
    message.setIsFavorite(isFavorite)
  }

  req.setName('UpdateUserChannelRequest')
  req.setUpdateUserChannel(message)
  return req
}

export const unreadCountRequest = () => {
  // const message = new GetUnreadCountRequest()
  // const req = RequestConstructor()
  // req.setName('GetUnreadCountRequest')
  // req.setGetUnreadCount(message)
  // return req

  const message = new GetUnreadCountRequest()
  const req = new Request()
  const authData = store.getState().authStore
  const selectUser = authData.selectUser
  req.setId(`${selectUser}`)
  req.setName('GetUnreadCountRequest')
  req.setGetUnreadCount(message)
  return req
}

interface convertImgListProps {
  id: string
}
export const convertImgList = (imgList: Array<convertImgListProps>) => {
  return imgList.map((item) => {
    const imgType = new Image()
    imgType.setId(item.id)
    return imgType
  })
}

export const getChannelListHeader = () => {
  const message = new GetChannelListHeaderRequest()
  const req = RequestConstructor()
  req.setName('GetChannelListHeaderRequest')
  req.setGetChannelListHeader(message)
  return req
}

export const closeChannelListHeader = (id: string) => {
  const message = new CloseChannelListHeaderRequest()
  const req = RequestConstructor()
  req.setId(id)
  req.setName('CloseChannelListHeaderRequest')
  req.setCloseChannelListHeader(message)
  return req
}

// messageType 0 삭제, 1 가리기(방장이 가리기)
export const deleteMessageItem = (channelId: string, messageId: number, messageType: 0 | 1) => {
  const message = new DeleteMessageRequest()
  const req = RequestConstructor()
  message.setChannelId(channelId)
  message.setMessageId(messageId)
  message.setDeleteByRole(messageType)
  req.setName('DeleteMessageRequest')
  req.setDeleteMessage(message)
  return req
}

export const getAutoReplyList = (targetBizId?: number) => {
  const message = new ListAutoRepliesRequest()
  const req = RequestConstructor()
  if (targetBizId) {
    req.setBizAccountId(targetBizId)
  }
  req.setName('ListAutoRepliesRequest')
  req.setListAutoReplies(message)
  return req
}

export const submitAutoReply = (question: string, answer: string, image?: autoReplyImage | false) => {
  const message = new CreateAutoReplyRequest()
  const req = RequestConstructor()

  const questionObj = new AutoReplyQuestion()
  questionObj.setText(question)
  message.setQuestion(questionObj)

  const answerObj = new AutoReplyAnswer()
  answerObj.setText(answer)

  if (image) {
    const imageObj = new Image()
    imageObj.setId(image.id)
    imageObj.setUrl(image.url)
    imageObj.setHeight(image.height)
    imageObj.setWidth(image.width)
    answerObj.setImagesList([imageObj])
  }

  message.setAnswer(answerObj)
  req.setName('CreateAutoReplyRequest')
  req.setCreateAutoReply(message)
  return req
}

export const updateAutoReply = (replyId: string, question: string, answer: string, image: autoReplyImage | false) => {
  const message = new UpdateAutoReplyRequest()
  const req = RequestConstructor()

  const questionObj = new AutoReplyQuestion()
  questionObj.setText(question)
  message.setQuestion(questionObj)

  const answerObj = new AutoReplyAnswer()
  answerObj.setText(answer)

  if (image) {
    const imageObj = new Image()
    imageObj.setId(image.id)
    imageObj.setUrl(image.url)
    imageObj.setHeight(image.height)
    imageObj.setWidth(image.width)
    answerObj.setImagesList([imageObj])
  } else {
    answerObj.setImagesList([])
  }

  message.setAnswer(answerObj)
  message.setId(replyId)
  req.setName('UpdateAutoReplyRequest')
  req.setUpdateAutoReply(message)
  return req
}

export const deleteAutoReply = (replyId: string) => {
  const message = new DeleteAutoReplyRequest()
  const req = RequestConstructor()
  message.setId(replyId)
  req.setName('DeleteAutoReplyRequest')
  req.setDeleteAutoReply(message)
  return req
}

export const getReplyMeta = () => {
  const message = new GetAutoReplyMetadataRequest()
  const req = RequestConstructor()
  req.setName('GetAutoReplyMetadataRequest')
  req.setGetAutoReplyMetadata(message)
  return req
}

export const updateReplyMeta = (metaData: autoReplyMetaProps) => {
  const message = new UpdateAutoReplyMetadataRequest()
  const req = RequestConstructor()
  message.setActive(metaData.active)
  req.setName('UpdateAutoReplyMetadataRequest')
  req.setUpdateAutoReplyMetadata(message)
  return req
}

export const questionAutoReply = (channelId: string, payload: string) => {
  const message = new SendPersistentMenuPostbackRequest()
  const req = RequestConstructor()
  message.setChannelId(channelId)
  message.setPayload(payload)
  req.setName('SendPersistentMenuPostbackRequest')
  req.setSendPersistentMenuPostback(message)
  return req
}