import React, { useCallback, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { StickerWrapper, StickerPreview, StickerItem } from './ChatSendStyle'
import { StoreState } from 'store/ducks'
import { StickerIcon } from 'utils/SVGIcons'
import { gtagInputBoxWrapper } from 'utils/eventWrapper'
import { convertImgList } from 'utils/protobuf/query'

interface ChatStickerProps {
  handleSendMessage: Function
  showSticker: boolean
  setShowSticker: React.Dispatch<React.SetStateAction<boolean>>
}
const ChatSticker = ({ handleSendMessage, showSticker, setShowSticker }: ChatStickerProps) => {
  const handleClick = (e: React.SyntheticEvent) => {
    e.preventDefault()
    e.stopPropagation()
    gtagInputBoxWrapper('sticker')
    setShowSticker((origin) => !origin)
  }
  const handleSelectSticker = (sticId: string) => {
    handleSendMessage({ type: 'STIC', imageList: convertImgList([{id: sticId}]) })
  }
  const stickerList = useSelector((state: StoreState) => state.chatStore.stickerList)
  const closeSticker = useCallback(() => setShowSticker(false), [setShowSticker])
  useEffect(() => {
    if (!showSticker) return

    window.addEventListener('click', closeSticker)
    return () => window.removeEventListener('click', closeSticker)
  }, [showSticker, closeSticker])

  return (
    <StickerWrapper className={`option-wrapper ${showSticker ? 'selected' : ''}`}>
      <button className="sticker-button" type="button" onClick={handleClick}>
        sticker
        <StickerIcon />
      </button>
      {showSticker && (
        <StickerPreview onClick={(e) => e.stopPropagation()}>
          {stickerList.map((stickerItem) => (
            <StickerItem className="common-bg-hover" key={stickerItem.id}>
              <img
                className={`sticker-img`}
                src={stickerItem.file}
                alt={`sticker ${stickerItem.name}`}
                onClick={() => handleSelectSticker(stickerItem.pictureId)}
              />
            </StickerItem>
          ))}
        </StickerPreview>
      )}
    </StickerWrapper>
  )
}

export default ChatSticker
