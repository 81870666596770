import React from 'react'
import { MessageTypes, ModalTypes } from 'types/ChatTypes'
import { StickerElement, ImagTypeWrapper, ImageWrapper } from './ChatMessageStyle'
import TemplateMessage from './TemplateMessage'
import ImageGallery from './ImageGallery'
import { ImgType } from 'types/ChatTypes'
import { WarningIcon } from 'utils/SVGIcons'

interface ChatContentProps {
  messageItem: MessageTypes
  setModalObj: React.Dispatch<React.SetStateAction<ModalTypes>>
}
const ChatContent = ({ messageItem, setModalObj }: ChatContentProps) => {
  const handleImageNewWindow = () => {
    const windowFeatures = ''
    window.open(messageItem.imageUrl, '_blank', windowFeatures)
  }
  const ShowModal = (imageList: Array<ImgType>, idx: number) => {
    setModalObj((origin) => ({
      ...origin,
      isShow: true,
      content: <ImageGallery imageList={imageList} currentIdx={idx} />,
    }))
  }
  if (messageItem.type === 'STIC') {
    const image = messageItem.imagesList?.[0]
    return (
      <div>
        {image ? (
          <StickerElement src={image.url} alt="sticker"/>
        ) : (
          <p className="message-box">{messageItem.content}</p>
        )}
      </div>
    )
  } else if (messageItem.type === 'IMAG') {
    const imageUrl = messageItem.imagesList?.[0]?.url ?? ''
    const pngCheck = imageUrl.indexOf('.png') > -1 || imageUrl.indexOf('.PNG') > -1
    return (
      <ImagTypeWrapper>
        <img
          src={imageUrl}
          onClick={handleImageNewWindow}
          className={`image-element common-bg-hover ${pngCheck ? 'extension-png' : ''}`}
          alt={`${messageItem.receiver?.nickname}'s upload file`}
        />
        <div className="hover-effect" />
      </ImagTypeWrapper>
    )
  } else if (messageItem.type === 'GALL') {
    // idx === 2 and length === 3 이면 only-third-image
    // idx === 3 and length > 4 이면 overlay
    const myList = messageItem.imagesList
    return (
      <ImageWrapper className={myList.length === 0 ? 'single-gallery' : 'multi-gallery'}>
        {myList.slice(0, 4).map((imgItem, idx) => {
          const thirdClass = idx === 2 && myList.length === 3 ? 'only-third-image' : 'gallery-image'
          const forthClass = idx === 3 && myList.length > 4 ? 'overlay' : ''
          const count = myList.length - 4
          const pngCheck = imgItem.url.indexOf('.png') > -1 || imgItem.url.indexOf('.PNG') > -1
          return (
            <span key={idx} className="gallery-img-wrap">
              <img
                src={imgItem.url}
                onClick={() => ShowModal(myList, idx % 3)}
                className={`pointer-cursor ${thirdClass} ${pngCheck ? 'extension-png' : ''}`}
                alt={`${messageItem.receiver?.nickname}'s upload file`}
              />
              <div className="hover-effect" />
              {forthClass ? <div className="overlay">{`+${count}`}</div> : null}
            </span>
          )
        })}
      </ImageWrapper>
    )
  } else if (messageItem.type === 'TEMP') {
    return <TemplateMessage messageItem={messageItem} />
  } else if (messageItem.type === 'COUP') {
    return (
      <div>
        <p className="message-box">{messageItem.content}</p>
      </div>
    )
  } else if (messageItem.type === 'ARTI') {
    return (
      <div>
        <p className="message-box">{messageItem.content}</p>
      </div>
    )
  } else {
    if (messageItem.deleteTime) {
      return (
        <div>
          <p className={`message-box deleted-message`}>
            <span className="info-icon">
              <WarningIcon />
            </span>
            {messageItem.content}
          </p>
        </div>
      )
    } else {
      return (
        <div>
          <p className="message-box">{messageItem.content}</p>
        </div>
      )
    }
  }
}

export default ChatContent
